import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {MyGreenCountry} from "./services/mygreencountry/my-greencountry";
import {authGuard} from "./guards/auth.guard";
import {loginGuard} from "./guards/login.guard";
import {log} from "@angular-devkit/build-angular/src/builders/ssr-dev-server";
import {tokenAuthGuard} from "./guards/token-auth.guard";

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },

    {
        path: 'auth/:token',
        canActivate: [tokenAuthGuard],
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    },

    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
        canActivate: [authGuard],
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [loginGuard],
        title: 'My Green Country'
    },
    {
        path: 'registration',
        loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationPageModule),
        canActivate: [authGuard],
        title: 'Реєстрація'
    },
    {
        path: 'loyalty-program',
        loadChildren: () => import('./pages/loyalty-program/loyalty-program.module').then(m => m.LoyaltyProgramPageModule),
        canActivate: [loginGuard],
        title: 'Програма лояльності'
    },
    {
        path: 'study-history',
        loadChildren: () => import('./pages/study-history/study-history.module').then(m => m.StudyHistoryPageModule),
        canActivate: [loginGuard],
        title: 'Історія навчання'
    },
    {
        path: 'study-progress/:type/:id',
        loadChildren: () => import('./pages/study-progress/study-progress.module').then(m => m.StudyProgressPageModule),
        canActivate: [loginGuard],
        title: 'Успішність'
    },

    {
        path: 'schedule',
        loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule),
        canActivate: [loginGuard],
        title: 'Розклад'
    },

    {
        path: 'tests',
        loadChildren: () => import('./pages/tests/tests.module').then(m => m.TestsPageModule),
        canActivate: [loginGuard],
        title: 'Тести та результати'
    },
    {
        path: 'certificates',
        loadChildren: () => import('./pages/certificates/certificates.module').then(m => m.CertificatesPageModule),
        canActivate: [loginGuard],
        title: 'Мої сертифікати'
    },
    {
        path: 'student-progress',
        loadChildren: () => import('./pages/student-progress/student-progress.module').then(m => m.StudentProgressPageModule),
        canActivate: [loginGuard],
        title: 'Мій прогрес'
    },
    {
        path: 'textbook/:id',
        loadChildren: () => import('./pages/textbook/textbook.module').then(m => m.TextbookPageModule),
        canActivate: [loginGuard],
        title: 'Всі матеріали'
    },

    {
        path: 'countrics',
        loadChildren: () => import('./pages/countrics/countrics.module').then(m => m.CountricsPageModule),
        canActivate: [loginGuard],
        title: 'Мої кантрики'
    },
    {
        path: 'certificate/:uuid',
        loadChildren: () => import('./pages/certificate/certificate.module').then(m => m.CertificatePageModule),
        canActivate: [],
        title: 'Сертифікат'
    },

    {
        path: 'faq',
        loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule),
        canActivate: [loginGuard],
        title: 'Часті питання'
    },

    {
        path: '404',
        loadChildren: () => import('./pages/page404/page404.module').then(m => m.Page404PageModule),
        canActivate: [loginGuard],
    },

    {
        path: '**',
        redirectTo: '/404'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
